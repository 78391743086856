import React, { useEffect, useRef } from "react";
import Select from "react-select";
import Creatable from "react-select/creatable";

import { useIsLarge } from "../../utils/hooks";

const Select2 = React.forwardRef(({ autofocus, isCreatable, ...props }: any, fwRef) => {
  const isLarge = useIsLarge();
  const innerRef = useRef();
  const ref: any = fwRef || innerRef;

  useEffect(() => {
    if (autofocus) ref.current && ref.current.focus();
  }, [autofocus, ref]);

  function findSelectedOption(value: any) {
    for (let option of props.options) {
      if (props.getOptionValue(option) === value) {
        return option;
      }
    }

    return null;
  }

  const value = props.isMulti ? props.value.map(findSelectedOption) : findSelectedOption(props.value);
  const isSearchable = !!(isLarge || props.options?.length > 30 || isCreatable);
  const Component = isCreatable ? Creatable : Select;

  return <Component menuPlacement="auto" isSearchable={isSearchable} {...props} ref={ref} value={value} />;
});

Select2.defaultProps = {
  isOptionDisabled: (option: any) => option.isDisabled,
  getOptionLabel: (option: any) => option.label,
  getOptionValue: (option: any) => option.value,
};

export default Select2;
